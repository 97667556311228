import Vue from "vue";
import VueRouter from "vue-router";

import configurations from "commons/configurations";
import { AUTH_NAMESPACE, USER } from "store/modules/auth.js";

Vue.use(VueRouter);

// Rota utilizada apenas se o sistema estiver em manutenção
const routesManutencao = [
  {
    path: "*",
    name: "manutencao",
    component: () => import("~/views/ManutencaoView.vue"),
    meta: { title: configurations.app.name },
  },
]
// Todas as Rotas do sistema, ao criar uma nova rota, verificar se necessita da metatag 'breadcrumb'
const routes = [
  {
    path: "*",
    name: "not-found",
    component: () => import("~/views/404View.vue"),
    meta: { title: configurations.app.name },
  },
  {
    path: "/",
    component: () => import( /* webpackChunkName: "pre-login" */ "views/pre-login/BasePreLogin.vue"),
    children: [
      {
        path: "/",
        name: "Landing Page",
        component: () => import( /* webpackChunkName: "pre-login" */ "views/pre-login/BaseLandingPage.vue"),
        meta: { title: configurations.app.name },
      },
      {
        path: "login",
        name: "Login",
        component: () => import( /* webpackChunkName: "pre-login" */ "views/pre-login/Login.vue"),
        meta: { title: "Entrar" },
      },
    ],
  },
  {
    path: "/app",
    component: () => import("views/post-login/BasePostLogin.vue"),
    meta: {
      requiresAuth: true,
      title: "Início",
    },
    children: [
      {
        path: "/",
        name: "welcome",
        component: () => import( /* webpackChunkName: "post-login" */ "views/post-login/Welcome.vue"),
        meta: {
          title: "Início",
        },
      },
      // Rotas do Módulo Profissional
      {
        path: "profissional/atualizar-dados-cadastrais",
        name: "Atualizar Dados Cadastrais",
        component: () => import( /* webpackChunkName: "profissional" */ "~/views/post-login/profissional/AtualizarDadosCadastraisView.vue"),
        meta: {
          title: "Atualizar Dados Cadastrais",
          breadcrumb: "Profissional/Atualizar Dados Cadastrais",
        },
      },
      {
        path: "profissional/consentimento-dados-pessoais",
        name: "Consentimento de Uso de Dados Pessoais",
        component: () => import( /* webpackChunkName: "profissional" */ "~/views/post-login/profissional/ConsentimentoDadosPessoaisView.vue"),
        meta: {
          title: "Consentimento de Uso de Dados Pessoais",
          breadcrumb: "Profissional/Consentimento de Uso de Dados Pessoais",
        },
      },
      // Comprovar Ação de Capacitação
      {
        path: "profissional/comprovar-acao-de-capacitacao",
        name: "Comprovar Ação de Capacitação",
        component: () => import( /* webpackChunkName: "profissional" */ "~/views/post-login/profissional/comprovacao-acao-capacitacao/ListarComprovacaoAcaoCapacitacaoView.vue"),
        meta: {
          title: "Comprovar Ação de Capacitação",
          breadcrumb: "Profissional/Comprovar Ação de Capacitação",
        },
      },
      {
        path: "profissional/cadastrar-comprovacao-acao-de-capacitacao",
        name: "Cadastrar Comprovação de Ação de Capacitação",
        component: () => import( /* webpackChunkName: "profissional" */ "~/views/post-login/profissional/comprovacao-acao-capacitacao/CadastrarComprovacaoAcaoCapacitacaoView.vue"),
        meta: {
          title: "Cadastrar Comprovação de Ação de Capacitação",
          breadcrumb: "Profissional/Cadastrar Comprovação de Ação de Capacitação",
        },
      },
      {
        path: "profissional/editar-comprovacao-acao-de-capacitacao",
        name: "Editar Comprovação de Ação de Capacitação",
        component: () => import( /* webpackChunkName: "profissional" */ "~/views/post-login/profissional/comprovacao-acao-capacitacao/CadastrarComprovacaoAcaoCapacitacaoView.vue"),
        meta: {
          title: "Editar Comprovação de Ação de Capacitação",
          breadcrumb: "Profissional/Editar Comprovação de Ação de Capacitação",
        },
      },
      {
        path: "profissional/visualizar-comprovacao-acao-de-capacitacao",
        name: "Visualizar Comprovação de Ação de Capacitação",
        component: () => import( /* webpackChunkName: "profissional" */ "~/views/post-login/profissional/comprovacao-acao-capacitacao/VisualizarComprovacaoAcaoCapacitacaoView.vue"),
        meta: {
          title: "Visualizar Comprovação de Ação de Capacitação",
          breadcrumb: "Profissional/Visualizar Comprovação de Ação de Capacitação",
        },
      },
      // Rotas do Módulo Administrador
      {
        path: "admin/listar-validacoes-comprovacao-acao-de-capacitacao",
        name: "Listar Validacoes Comprovação de Ação de Capacitação",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/validar-acao-capacitacao/ListarValidacoesComprovacaoAcaoCapacitacaoView.vue"),
        meta: {
          title: "Listar Validacoes Comprovação de Ação de Capacitação",
          breadcrumb: "Administrador/Listar Validacoes Comprovação de Ação de Capacitação",
        },
      },
      {
        path: "admin/listar-relatorio-pagamento-avp",
        name: "Listar Relatório de Pagamento do AVP",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosDePagamentoView.vue"),
        meta: {
          title: "Listar Relatório de Pagamento do AVP",
          breadcrumb: "Administrador/Relatórios/Listar Relatório de Pagamento AVP",
        },
      },
      {
        path: "admin/listar-relatorio-validacao-acao",
        name: "Listar Relatório de Validação da Ação",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosDeValidacaoAcaoView.vue"),
        meta: {
          title: "Listar Relatório de Validação da Ação",
          breadcrumb: "Administrador/Relatórios/Listar Relatório de Validação da Ação",
        },
      },
      {
        path: "admin/listar-relatorio-quantitativo-ciclo",
        name: "Listar Relatório Quantitativo por Ciclo",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosQuantitativoPorCiclo.vue"),
        meta: {
          title: "Listar Relatório Quantitativo por Ciclo",
          breadcrumb: "Administrador/Relatórios/Listar Relatório Quantitativo por Ciclo",
        },
      },
      {
        path: "admin/listar-relatorio-log-faq",
        name: "Listar Relatório Log de FAQ",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosLogFaq.vue"),
        meta: {
          title: "Listar Relatório Quantitativo por Ciclo",
          breadcrumb: "Administrador/Relatórios/Listar Relatório Log de FAQ",
        },
      },
      {
        path: "admin/listar-relatorio-log-termo",
        name: "Listar Relatório Log de Termo",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosLogTermo.vue"),
        meta: {
          title: "Listar Relatório de Log de Termo",
          breadcrumb: "Administrador/Relatórios/Listar Relatório Log de Termo",
        },
      },
      {
        path: "admin/listar-relatorio-log-validacao-comprovacao",
        name: "Listar Relatório de Log de Validação de Comprovação de Capacitação",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosLogValidacaoComprovacao.vue"),
        meta: {
          title: "Listar Relatório de Log de Validação de Comprovação de Capacitação",
          breadcrumb: "Administrador/Relatórios/Listar Relatório de Log de Validação de Comprovação de Capacitação",
        },
      },
      {
        path: "admin/listar-relatorio-log-usuario",
        name: "Listar Relatório Log de Usuários",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosLogUsuario.vue"),
        meta: {
          title: "Listar Relatório Log de Usuários",
          breadcrumb: "Administrador/Relatórios/Listar Relatório Log de Usuários",
        },
      },
      {
        path: "admin/listar-relatorio-log-configuracao-edicao",
        name: "Listar Relatório Log de Configuracao de Edicao",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosDeLogDeConfiguracaoEdicao.vue"),
        meta: {
          title: "Listar Relatório de Configuração de Edição",
          breadcrumb: "Administrador/Relatórios/Listar Relatório Log de Configuração de Edição",
        },
      },
      {
        path: "admin/listar-relatorio-acao-capacitacao",
        name: "Listar Relatório de Ação de Capacitação",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosAcaoDeCapacitacaoView.vue"),
        meta: {
          title: "Listar Relatório de Ação de Capacitação",
          breadcrumb: "Administrador/Relatórios/Listar Relatório de Ação de Capacitação",
        },
      },
      {
        path: "admin/relatorio-usuarios-sem-direito-beneficio",
        name: "Listar Relatório de Usuários Sem Benefício",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosProfissionalSemBeneficioView.vue"),
        meta: {
          title: "Relatório de Usuários Sem Direito ao Benefício",
          breadcrumb: "Administrador/Relatórios/Relatório de Usuários Sem Direito ao Benefício",
        },
      },
      {
        path: "admin/listar-relatorio-usuarios",
        name: "Listar Relatório de Usuários",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosUsuariosView.vue"),
        meta: {
          title: "Listar Relatório de Usuários",
          breadcrumb: "Administrador/Relatórios/Listar Relatório de Usuários",
        },
      },      
      {
        path: "admin/listar-log-comprovacao-acao-capacitacao",
        name: "Listar Relatório de Log de Comprovação de Capacitação",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosDeLogDeComprovacaoDeCapacitacao.vue"),
        meta: {
          title: "Listar Relatório de Log de Comprovação de Capacitação",
          breadcrumb: "Administrador/Relatórios/Listar Relatório de Log de Comprovação de Capacitação",
        },
      },
      {
        path: "admin/listar-relatorio-log-profissional",
        name: "Listar Relatório de Log de Profissional",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/relatorios/ListarRelatoriosDeLogDeProfissional.vue"),
        meta: {
          title: "Listar Relatório de Log de Profissional",
          breadcrumb: "Administrador/Relatórios/Listar Relatório de Log de Profissional",
        },
      },
      {
        path: "admin/gerenciar-faq",
        name: "Gerenciar FAQ",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/manter-faq/ManterFAQView.vue"),
        meta: {
          title: "Gerenciar FAQ",
          breadcrumb: "Administrador/Gerenciar FAQ",
        },
      },
      {
        path: "admin/gerenciar-instituicao",
        name: "Gerenciar Instituição",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/manter-instituicao/ManterInstituicaoView.vue"),
        meta: {
          title: "Gerenciar Instituição",
          breadcrumb: "Administrador/Gerenciar Instituição",
        },
      },
      {
        path: "admin/gerenciar-tipo-acao-capacitacao",
        name: "Gerenciar Tipo de Ação de Capacitação",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/manter-tipo-acao-capacitacao/ManterTipoAcaoCapacitacaoView.vue"),
        meta: {
          title: "Gerenciar Tipo de Ação de Capacitação",
          breadcrumb: "Administrador/Gerenciar Tipo de Ação de Capacitação",
        },
      },
      {
        path: "admin/gerenciar-ciclo",
        name: "Gerenciar Ciclo",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/manter-ciclo/ManterCicloView.vue"),
        meta: {
          title: "Gerenciar Ciclo",
          breadcrumb: "Administrador/Gerenciar Ciclo",
        },
      },
      {
        path: "admin/gerenciar-parametrizacao-comprovacao",
        name: "Gerenciar Parametros da Comprovação",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/manter-parametrizacao/parametros-comprovacao-acao/ManterParametrosComprovacaoView.vue"),
        meta: {
          title: "Gerenciar Parâmetros",
          breadcrumb: "Administrador/Gerenciar Parâmetros da Comprovação",
        },
      },
      {
        path: "admin/gerenciar-eixo-tematico",
        name: "Gerenciar Eixo Temático",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/manter-eixo-tematico/ManterEixoTematicoView.vue"),
        meta: {
          title: "Gerenciar Eixo Temático",
          breadcrumb: "Administrador/Gerenciar Eixo Temático",
        },
      },
      {
        path: "admin/gerenciar-trilha-de-aprendizagem",
        name: "Gerenciar Trilha de Aprendizagem",
        component: () => import( /* webpackChunkName: "admin" */ "~/views/post-login/administrador/manter-trilha-de-aprendizagem/ManterTrilhaDeAprendizagemView.vue"),
        meta: {
          title: "Gerenciar Trilha de Aprendizagem",
          breadcrumb: "Administrador/Gerenciar Trilha de Aprendizagem",
        }
      },
      {
        path: 'admin/gerenciar-grupo',
        name: 'Gerenciar Grupo',
        component: () => import( /* webpackChunkName: "admin" */ 'views/post-login/administrador/manter-grupos/ManterGruposView.vue'),
        meta: {
          title: 'Gerenciar Grupo',
          breadcrumb: "Administrador/Gerenciar Grupos",
        },
      },
      {
        path: 'admin/gerenciar-perfil',
        name: 'Gerenciar Perfil',
        component: () => import( /* webpackChunkName: "admin" */ 'views/post-login/administrador/manter-perfis/ManterPerfisView.vue'),
        meta: {
          title: 'Gerenciar Perfil',
          breadcrumb: "Administrador/Gerenciar Perfis",
        },
      },
      {
        path: 'admin/gerenciar-termo',
        name: 'Gerenciar Termo',
        component: () => import( /* webpackChunkName: "admin" */ 'views/post-login/administrador/manter-termos/ManterTermosView.vue'),
        meta: {
          title: 'Gerenciar Termo',
          breadcrumb: "Administrador/Termo",
        },
      },
      {
        path: 'admin/gerenciar-usuario',
        name: 'Gerenciar Usuário',
        component: () => import( /* webpackChunkName: "admin" */ 'views/post-login/administrador/manter-usuarios/ManterUsuariosView.vue'),
        meta: {
          title: 'Gerenciar Usuario',
          breadcrumb: "Administrador/Gerenciar Usuarios",
        },
      },
      {
        path: 'admin/gerenciar-motivo',
        name: 'Gerenciar Motivo',
        component: () => import( /* webpackChunkName: "admin" */ 'views/post-login/administrador/manter-motivo/ManterMotivoView.vue'),
        meta: {
          title: 'Gerenciar Motivo',
          breadcrumb: "Administrador/Gerenciar Motivo",
        },
      },
      {
        path: 'admin/gerenciar-faixa-pagamento',
        name: 'Gerenciar Faixa de Pagamento',
        component: () => import( /* webpackChunkName: "admin" */ 'views/post-login/administrador/manter-faixa-pagamento/ManterFaixaDePagamentoView.vue'),
        meta: {
          title: 'Gerenciar Faixa de Pagamento',
          breadcrumb: "Administrador/Gerenciar Faixa de Pagamento",
        }
      },
      {
        path: 'admin/gerenciar-verba-pagamento',
        name: 'Gerenciar Verba de Pagamento',
        component: () => import( /* webpackChunkName: "admin" */ 'views/post-login/administrador/manter-verba-pagamento/ManterVerbaPagamentoView.vue'),
        meta: {
          title: 'Gerenciar Verba de Pagamento',
          breadcrumb: "Administrador/Gerenciar Verba de Pagamento",
        },
      },
      // Demais Rotas
      {
        path: 'configurar/pagina-inicial',
        name: 'configurelandingpage',
        component: () => import( /* webpackChunkName: "configuracao" */ 'views/post-login/base-configurations/CustomizeLandingPage.vue'),
        meta: {
          title: 'Configurar Página Inicial'
        },
      },
      {
        path: 'configurar/perfis',
        name: 'configureprofiles',
        component: () => import( /* webpackChunkName: "configuracao" */ 'views/post-login/base-configurations/permissions/Profiles.vue'),
        meta: {
          title: 'Configurar Perfis'
        },
      },
    ],
  }
];
const routerFactory = (store) => {
  const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: configurations.env.manutencao == "true" ? routesManutencao : routes,
  });

  const updateThemeAndBackground = (to) => {
    router.app.$isUserAdmin()
      ? router.app.$setThemeAdmin()
      : router.app.$setThemeProfissional();
    to.meta.showBackground
      ? router.app.showDefaultBackgroundImage()
      : router.app.hideDefaultBackgroundImage();
  }

  const updateTitleAndMetadata = (to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
    const nearestWithMeta = to.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find((r) => r.meta && r.meta.metaTags);

    if (nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }

    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).forEach((el) =>
      el.parentNode.removeChild(el)
    );

    if (!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags
      .map((tagDef) => {
        const tag = document.createElement("meta");
        Object.keys(tagDef).forEach((key) => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute("data-vue-router-controlled", "");
        return tag;
      })
      .forEach((tag) => document.head.appendChild(tag));
  }

  const authorize = (to, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const user = store.getters[`${AUTH_NAMESPACE}/${USER}`];
      if (user) {
        next();
        return;
      }
      next({ path: "/login" });
    } else {
      next();
    }
  }

  router.beforeEach((to, from, next) => {
    updateTitleAndMetadata(to, from, next);
    authorize(to, next);
  });

  router.afterEach((to) => {
    updateThemeAndBackground(to);
  });

  return router;
};

export default routerFactory;
