export default {
  env: {
    nodeEnv: process.env.NODE_ENV,
    prodTip: process.env.PROD_TIP,
    devTools: process.env.DEV_TOOLS,
    sitekey: process.env.VUE_APP_SITEKEY,
    manutencao: process.env.VUE_APP_MANUTENCAO,
    recaptcha: process.env.VUE_APP_CAPTCHA,
  },
  app: {
    name: process.env.VUE_APP_NAME,
    alias: process.env.VUE_APP_ALIAS,
    version: process.env.VUE_APP_VERSION,
  },
  layout: {
    preLogin: {
      backgroundImageUri:
        process.env.VUE_APP_LAYOUT_PRE_LOGIN_BACKGROUND_IMAGE_URI || "/img/bg-01.jpg",
    },
  },
  api: {
    baseUri: process.env.VUE_APP_API_BASE_URI,
  },
  google: {
    id: process.env.VUE_APP_GOOGLE_ID,
  },
};
